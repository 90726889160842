.App {
  display: flex;
  flex-direction: row;
  font-family: "Chakra Petch", sans-serif;
  height: 96vh;
  margin: 2vh;
}

.AboutMe {
  background-color: #ddddc7;
  color: #552e5a;
  display: flex;
  flex-direction: column;
  font-size: 3em;
  font-weight: 600px;
  text-align: left;
  padding: 5em 1em 1em 1em;
}

.AboutMe_header {
  color: #dc7646;
  font-family: "DotGothic16", sans-serif;
  letter-spacing: 0.1em;
  margin: 0 0 0.3em 0;
}

.AboutMe_body {
  display: flex;
  flex-flow: row wrap;
  font-size: 0.5em;
  line-height: 1.5;
  padding: 0 0 1em 0;
}

.Links {
  background-color: #552e5a;
  display: flex;
  flex-direction: column;
  padding: 2em;
}

.Links_link {
  background-color: transparent;
  border: none;
  color: #ecffa3;
  font-family: "DotGothic16", sans-serif;
  font-size: 2.3em;
  font-weight: 600;
  justify-items: flex-end;
  letter-spacing: 0.3em;
  margin: 0.7em 0 0.7em 0;
  outline: none;
  text-align: right;
  text-decoration: none;
  transition: 0.1 ease-in-out;
}

.Links_link:hover {
  color: #c5d86d;
  cursor: pointer;
}

#portfolio-link:hover {
  transform: rotate(7deg);
  -moz-transform: rotateZ(7deg);
  -webkit-transform: rotateZ(7deg);
  -o-transform: rotateZ(7deg);
}

#experience-link:hover {
  transform: rotate(-7deg);
  -moz-transform: rotateZ(-7deg);
  -webkit-transform: rotateZ(-7deg);
  -o-transform: rotateZ(-7deg);
}

#contact-link:hover {
  transform: rotate(7deg);
  -moz-transform: rotateZ(7deg);
  -webkit-transform: rotateZ(7deg);
  -o-transform: rotateZ(7deg);
}

#twitter-icon {
  height: 0.6em;
  width: 0.6em;
}

#twitter-link:hover {
  transform: rotate(-7deg);
  -moz-transform: rotateZ(-7deg);
  -webkit-transform: rotateZ(-7deg);
  -o-transform: rotateZ(-7deg);
}

.Modal {
  background-color: #dc7646;
  color: #552e5a;
  display: flex;
  flex-direction: column;
  font-family: "Chakra Petch", sans-serif;
  width: 36em;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Modal_close {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: flex-end;
  transition: 0.2 ease-in-out;
}

.Modal_close_button {
  background-color: transparent;
  border: none;
  color: #ecffa3;
  font-family: "DotGothic16", sans-serif;
  font-size: 2.7em;
  height: fit-content;
  padding: 0 0.5em 0 0;
}

.Modal_close_button:hover {
  cursor: pointer;
  transform: rotate(-17deg);
}

.Modal_header {
  font-size: 1.7em;
  justify-content: center;
  margin: 0.25em 0 0.75em 0;
  text-align: center;
  width: inherit;
}

#popup-1 {
  background-color: transparent;
  border: none;
  padding: 0;
}

#popup-2 {
  border: none;
  padding: 0;
}

.Modal_content {
  justify-content: center;
  width: 100%;
}

.Contact {
  display: flex;
}

.Contact_form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Contact_section {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.Contact_label {
  display: flex;
  font-size: 1.1em;
  justify-content: flex-start;
  width: 80%;
  margin: 0;
}

.Contact_input {
  background-color: #f8f8f8;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 1.1em;
  height: 2em;
  margin: 0.2em 0 0.8em 0;
  width: 80%;
}

.Contact_textarea {
  background-color: #f8f8f8;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 1.2em;
  height: 8em;
  margin: 0.2em 0 0.8em 0;
  resize: none;
  width: 80%;
}

.Contact_textarea:focus,
.Contact_input:focus {
  outline: none;
}

.Contact_submit_button {
  background-color: transparent;
  border: none;
  color: #ecffa3;
  display: flex;
  font-size: 1.6em;
  font-family: "DotGothic16", sans-serif;
  font-weight: 400;
  justify-content: center;
  letter-spacing: 0.2em;
  margin: 0.6em;
  max-width: 100%;
  outline: none;
}

.Contact_submit_button:hover {
  cursor: pointer;
  transform: rotate(-10deg);
}

@media only screen and (max-width: 900px) {
  .App {
    flex-direction: column-reverse;
    height: inherit;
    justify-content: space-between;
  }

  .Links {
    padding: 1em;
  }

  .Contact_input {
    margin: 0.5em;
    height: 2em;
    font-size: 0.9em;
    width: 85%;
  }

  .Contact_textarea {
    font-size: 1em;
    height: 9em;
    margin: 0.5em;
    width: 85%;
  }

  .Contact_label {
    width: 85%;
  }

  .AboutMe {
    padding: 1em;
  }

  .Modal {
    display: flex;
    width: 25em;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1000px) {
  .AboutMe {
    padding: 3.5em 1em 1em 1em;
  }
}

@media only screen and (max-height: 550px) {
  .AboutMe {
    padding: 1em;
  }
}
